export default {
  cake: {
    42262: '0x363E23D1e0c8b06Ff0684f7Ed6eFE0A83541a424',
    97: '',
  },
  masterChef: {
    42262: '0x5D20Be605F2AF5612D0D2C4D99765e6eD9f8C48B',
    97: '',
  },
  wbnb: {
    42262: '0x21C718C22D52d0F3a789b752D4c2fD5908a8A733',
    97: '',
  },

  eth: {
    42262: '0xE9b38eD157429483EbF87Cf6C002cecA5fd66783',
    97: '',
  },
  lottery: {
    42262: '',
    97: '',
  },

  usdc: {
    42262: '0x94fbfFe5698DB6f54d6Ca524DbE673a7729014Be',
    97: '',
  },
  btc: {
    42262: '0x010CDf0Db2737f9407F8CFcb4dCaECA4dE54c815',
    97: '',
  },
  lotteryNFT: {
    42262: '',
    97: '',
  },
  mulltiCall: {
    42262: '0x970F9F4d7D752423A82f4a790B7117D949939B0b',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    42262: '0x6Cb9750a92643382e020eA9a170AbB83Df05F30B',
    97: '',
  },

  busdWorm: {
    42262: '0xdC19A122e268128B5eE20366299fc7b5b199C8e3',
    97: '',
  },
}
