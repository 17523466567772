import React from 'react'
import styled from 'styled-components'
import { Text, Dropdown, Link } from '@pancakeswap-libs/uikit'

const StyledMobileBar = styled.div`
  height: 4rem;
  width: 100%;
  position: fixed !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  bottom: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  z-index: 9;
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364);
  ${({ theme }) => theme.colors.background};
  padding: 0 1rem;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: none;
  }
`

const MobileBar = () => {
  return (
    <StyledMobileBar>
      <Dropdown position="top-right" target={<Text fontSize="1.2rem">Trade</Text>}>
        <Link href="https://dex.rimswap.app/#/swap">
          <Text fontSize="1.2rem">Exchange</Text>
        </Link>
        <Link href="https://dex.rimswap.app/#/pool">
          <Text fontSize="1.2rem">Liquidity</Text>
        </Link>
      </Dropdown>

      <Link href="https://rimswap.app/farms">
        <Text fontSize="1.2rem">Farms</Text>
      </Link>
      <Link href="https://rimswap.app/pools">
        <Text fontSize="1.2rem">Pools</Text>
      </Link>
      <Link href="https://www.oasisgorillas.com/">
        <Text fontSize="1.2rem">NFT</Text>
      </Link>

      <Dropdown position="top-right" target={<Text fontSize="1.2rem">Bridge</Text>}>
        <Link href="https://bridge.evodefi.com/">
          <Text fontSize="1.2rem">EvoDefi</Text>
        </Link>
        <Link href="https://portalbridge.com/#/transfer">
          <Text fontSize="1.2rem">WormHole</Text>
        </Link>
      </Dropdown>

      <Dropdown
        position="top-right"
        target={
          <Text fontSize="2rem" style={{ padding: '0 0 1rem 0' }}>
            ...
          </Text>
        }
      >
        <div style={{ margin: '0 5rem 0 0' }}>
          <Link target="_blank" href="/https://t.me/Rimswap_chat/">
            <Text fontSize="1.2rem">Contact</Text>
          </Link>
          <Link target="_blank" href="https://bit.ly/3w16iGb">
            <Text fontSize="1.2rem">Docs</Text>
          </Link>
          <Link target="_blank" href="https://bit.ly/3J62K9o">
            <Text fontSize="1.2rem">Tokenomics</Text>
          </Link>
          <Link target="_blank" href="https://bit.ly/3w3GTf7">
            <Text fontSize="1.2rem">Roadmap</Text>
          </Link>
          <Link target="_blank" href="https://twitter.com/RIMSwap">
            <Text fontSize="1.2rem">Twitter</Text>
          </Link>
          <Link target="_blank" href="https://t.me/Rimswap_chat">
            <Text fontSize="1.2rem">Telegram</Text>
          </Link>
          <Link target="_blank" href="https://discord.gg/uXWSuCdQcr">
            <Text fontSize="1.2rem">Discord</Text>
          </Link>
          <Link target="_blank" href="https://dexscreener.com/oasisemerald/0xf6e0656162c471e27bcf23ab6626ad5f66b70e18">
            <Text fontSize="1.2rem">Chart</Text>
          </Link>
        </div>
      </Dropdown>

      {/* <Text fontSize="1.2rem">Farms</Text>
            <Text fontSize="1.2rem">Pools</Text> */}
    </StyledMobileBar>
  )
}

export default MobileBar
