import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  // body {
  //   background-size: auto 100%;
  //    background-image: url('/bg1.gif');  
  //   background-attachment: fixed; 
  //   background-position: center center;
  //   ${({ theme }) => theme.mediaQueries.nav} { 
  //     background-size: 100% 100%;
  // }
  //   img {
  //     height: auto;
  //     max-width: 100%;
  //   }
  // }

  body {
    // background:  ${({ theme }) => theme.colors.background};

//     background: #ed213a;  /* fallback for old browsers */
// background: -webkit-linear-gradient(to right,#93291e,#100d22);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right,#93291e,#100d22);
    background-size: auto 100%;
    //  background-image: url('/bg1.gif');
    background-attachment: fixed; 
    background-position: center center;
    ${({ theme }) => theme.mediaQueries.nav} { 
      background-size: 100% 100%;
  }

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
